export default class VisualizationProperties {
	constructor() {
		this.card = {
			name: null,
			dataType: null,
			activeView: "table",
			location: null,
			parentLocation: null, // is needed only for drawing vis card, is not sent to BE
		}
		this.table = {
			columns: [{name: 'Injection', expr: 'injection.name', type: 'string', width:'140px'}],
			rowHeight: '24px',
			rowFilter: null
		}
		this.chart = {
			axes: {
				x: null,
				y: null,
			},
			lines: [],
			color: null,
			size: null,
			connections: null
		}
	}

	clone(){
		return VisualizationProperties.parseServerJson(JSON.parse(JSON.stringify(this)))
	}

	static parseServerJson(json){
		return Object.assign(new VisualizationProperties(), json)
	}

	static createDefault(type = "injections") {
		if (!["injections", "peaks"].includes(type))
			throw new Error(`Unsupported type: {${type}}, allowed types: ["injections", "peaks"]`)
		let props = new VisualizationProperties();
		props.card.dataType = type;
		props.card.name = type === "injections" ? "Injections" : "Peaks"
		return props;
	}
}
